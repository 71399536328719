import 'magnific-popup';
import "../../node_modules/slick-carousel/slick/slick";

jQuery( document ).ready(function($) {
    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');

        e.preventDefault();

        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.read-more-btn').on('click', function(e){
        e.preventDefault();
        $('.read-more-btn').hide();
        $('.more').fadeIn();
    })

    $('.lang-toggle').on('click', function(e){
        e.preventDefault();
        $('body').toggleClass('page-en');
        $('body').toggleClass('page-es');
    })

    $('.carousel').slick({
        slidesToShow: 1,
        prevArrow:"<button class='prev-arrow'><i class='fas fa-arrow-left'></i><span class='sr'>Previous</span></button>",
        nextArrow:"<button class='next-arrow'><i class='fas fa-arrow-right'></i><span class='sr'>Next</span></button>"
    });

    $('.mfp-inline').magnificPopup({});
});